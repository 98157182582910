import React from 'react'
import { Pill } from '@sh24/ui-components'
import { documentToReactComponents as contentfulDTRC } from '@contentful/rich-text-react-renderer'
import replacementTokenOptions from '../../../utils/document-to-react-component-renderers/replacement-token'

const PillModule = ({
  content,
  textSize = 'base',
  colour = '/black300',
  backgroundColour = '/secondary200',
  icon,
}) => (
  <Pill
    textSize={textSize}
    color={colour}
    backgroundcolor={backgroundColour}
    icon={icon}
    text={contentfulDTRC(content, replacementTokenOptions)}
  />
)

export default PillModule
