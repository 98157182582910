import formatPostcode from './format-postcode'

const REGEXP = /^(([A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKS-UW]))\s?[0-9][ABD-HJLNP-UW-Z]{2}|(GIR 0AA)|(SAN TA1)|(BFPO (C\/O )?[0-9]{1,4})|((ASCN|BBND|[BFS]IQQ|PCRN|STHL|TDCU|TKCA) 1ZZ))$/i

const REJECTREGEXP = /^IM|^JE|^GY/i

function validatePostcode(value) {
  const formattedPostcode = formatPostcode(value)

  if (formattedPostcode.match(REJECTREGEXP)) return false

  return !!formattedPostcode.match(REGEXP)
}

export default validatePostcode
