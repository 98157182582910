import ButtonLink from './variants/button-link'
import SlimLink from './variants/slim-link'

const ContentLinkModule = ({
  id,
  icon,
  fullWidth,
  linkStyle,
  url,
  text,
  noFollow,
  newTab,
  external,
}) => {
  if (linkStyle === 'slim') {
    return (
      <SlimLink
        id={id}
        url={url}
        text={text}
        noFollow={noFollow}
        newTab={newTab}
        external={external}
      />
    )
  }

  return (
    <ButtonLink
      id={id}
      icon={icon}
      fullWidth={fullWidth}
      linkStyle={linkStyle}
      url={url}
      text={text}
      noFollow={noFollow}
      newTab={newTab}
      external={external}
    />
  )
}

export default ContentLinkModule
