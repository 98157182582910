import 'core-js/es/object/from-entries'
import { useMemo, useState, useEffect } from 'react'
import { withCookies } from 'react-cookie'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { Amplify } from 'aws-amplify'
import {
  getTheme,
  GlobalStyles,
  UtilityClasses,
  PreviewBanner,
  getFonts,
  ButtonLink,
  withModal,
} from '@sh24/ui-components'
import { ErrorBoundary } from '@sentry/react'
import DefaultLayout from '../layouts/default'
import FontPreloads from '../components/Fonts/font-preloads'
import FontFaces from '../components/Fonts/font-faces'
import InlineLink from '../components/InlineLink/inline-link'
import AppContext from '../contexts/app-context'
import AccountContext, { createAccountContext } from '../contexts/account-context'
import SessionContext from '../contexts/session-context'

import {
  gtmEvent,
  performance,
  GTM_TRACKING_ID,
  GTM_PREVIEW_ENVIRONMENT_ID,
  GTM_AUTH_TOKEN,
} from '../services/gtm'
import GTMScriptTag from '../components/GoogleTagManager/script-tag'
import CookieConsent, { COOKIES_ACCEPTED, COOKIES_CONSENT_COOKIE } from '../components/CookieConsent/cookie-consent'
import cognitoConfig from '../configs/cognito'
import { getRefreshUser } from '../utils/session'
import useSessions from '../utils/use-sessions'

const ExitPreviewButton = () => (
  <ButtonLink variation="primary" animation="shiftRight" iconPosition="right">
    <InlineLink url="/api/preview/disable" text="Exit preview" iconName="arrow-right" external />
  </ButtonLink>
)

if (useSessions()) {
  Amplify.configure(cognitoConfig(), { ssr: true })
}

const App = ({ Component, pageProps, cookies }) => {
  const site = process.env.NEXT_PUBLIC_SITE
  const cookieConsent = cookies.get(COOKIES_CONSENT_COOKIE)
  const showCookieModal = process.browser && cookieConsent === undefined

  const {
    siteConfig,
    preview,
    translations,
    ...restPageProps
  } = pageProps

  const [appContext, setAppContext] = useState({
    ...siteConfig,
    mainMenu: pageProps?.page?.mainMenu || siteConfig?.mainMenu,
    loggedInAccountMenu: siteConfig?.loggedInAccountMenu,
    loggedOutAccountMenu: siteConfig?.loggedOutAccountMenu,
    preview,
    translations,
    resultCards: {},
  })

  const accountContextProviderValue = createAccountContext()

  const [user, setUser] = useState()
  const [sessionLoaded, setSessionLoaded] = useState(false)

  const refreshUser = getRefreshUser(setUser, setSessionLoaded)
  const sessionContextProviderValue = useMemo(
    () => ({
      user,
      setUser,
      sessionLoaded,
      setSessionLoaded,
      refreshUser,
    }),
    [user, setUser, sessionLoaded, setSessionLoaded, refreshUser],
  )

  useEffect(async () => {
    if (useSessions()) await refreshUser()
  }, [])

  const searchConfig = siteConfig?.search?.find((input) => input.location === 'global')

  const Layout = Component.Layout || DefaultLayout

  const CookieConsentModal = withModal(null, CookieConsent)

  const contextProviderValue = useMemo(() => ({ appContext, setAppContext }),
    [appContext, setAppContext])

  return (
    <>
      <FontPreloads fonts={getFonts(site)} Head={Head} />
      <FontFaces fonts={getFonts(site)} Head={Head} />
      {cookieConsent === COOKIES_ACCEPTED && (
        <GTMScriptTag
          GTM_TRACKING_ID={GTM_TRACKING_ID}
          GTM_PREVIEW_ENVIRONMENT_ID={GTM_PREVIEW_ENVIRONMENT_ID}
          GTM_AUTH_TOKEN={GTM_AUTH_TOKEN}
        />
      )}

      <ErrorBoundary fallback={<p>An error has occurred</p>}>
        <>
          <ThemeProvider theme={getTheme(site)}>
            <GlobalStyles />
            <UtilityClasses />

            <AppContext.Provider value={contextProviderValue}>
              {appContext?.preview && <PreviewBanner title="Preview" ExitPreviewButton={ExitPreviewButton} />}

              <SessionContext.Provider value={sessionContextProviderValue}>
                <AccountContext.Provider value={accountContextProviderValue}>
                  <Layout site={site} searchConfig={searchConfig}>
                    {showCookieModal && <CookieConsentModal mandatory />}
                    <Component key={pageProps?.key} {...restPageProps} />
                  </Layout>
                </AccountContext.Provider>
              </SessionContext.Provider>
            </AppContext.Provider>
          </ThemeProvider>
        </>
      </ErrorBoundary>
    </>
  )
}

export function reportWebVitals({
  id,
  name,
  label,
  value,
}) {
  gtmEvent({
    event: 'performance_metric',
    category: label === 'web-vital' ? 'Web Vitals' : 'Next.js Metric',
    action: performance[name],
    label: id,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    non_interaction: true,
  })
}

export default withCookies(App)
