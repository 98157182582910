import * as Sentry from '@sentry/react'

const SENTRY_PROJECT_URL = process.env.NEXT_PUBLIC_SENTRY_PROJECT_URL
const environment = process.env.NEXT_PUBLIC_APP_ENV || process.env.NODE_ENV
const googleSearchAppError = "Can't find variable: gmo"

Sentry.init({
  dsn: SENTRY_PROJECT_URL,
  tracesSampleRate: 1,
  debug: false,
  environment,
  ignoreErrors: [googleSearchAppError],
})

const needErrorReporting = ['qa', 'production'].includes(environment)

const notifyError = (error: unknown, context?: Record<string, unknown>) => {
  if (!needErrorReporting) {
    console.warn(error) // eslint-disable-line no-console
    return
  }

  Sentry.withScope((scope) => {
    if (context) {
      scope.setContext('Context', context)
    }

    if (typeof error === 'string') {
      Sentry.captureMessage(error)
    } else {
      Sentry.captureException(error)
    }
  })
}

export default notifyError
