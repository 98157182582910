import { SlimLink as SlimLinkComponent } from '@sh24/ui-components'
import InlineLink from '../../../InlineLink/inline-link'

const SlimLink = ({
  id,
  url,
  text,
  noFollow,
  newTab,
  external,
}) => (
  <div key={id} className="mb-md">
    <SlimLinkComponent>
      <InlineLink
        url={url}
        text={text}
        noFollow={noFollow}
        newTab={newTab}
        external={external}
        context="content"
      />
    </SlimLinkComponent>
  </div>
)

export default SlimLink
