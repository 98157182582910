import { ButtonLink as ButtonLinkComponent } from '@sh24/ui-components'
import InlineLink from '../../../InlineLink/inline-link'

const iconConfig = {
  'arrow-right': {
    animation: 'shiftRight',
    iconPosition: 'right',
  },
  'arrow-left': {
    animation: 'shiftLeft',
    iconPosition: 'left',
  },
  'external-link': {
    iconPosition: 'right',
  },
  none: {
    iconPosition: 'none',
  },
}

const ButtonLink = ({
  id,
  icon,
  fullWidth,
  linkStyle,
  url,
  text,
  noFollow,
  newTab,
  external,
}) => {
  const { animation, iconPosition } = iconConfig[icon || 'none']

  return (
    <div key={id} className="mb-md">
      <ButtonLinkComponent
        fullWidthOnMobile={fullWidth}
        variation={linkStyle}
        animation={animation}
        iconPosition={iconPosition}
      >
        <InlineLink
          url={url}
          text={text}
          noFollow={noFollow}
          newTab={newTab}
          external={external}
          iconPosition={iconPosition}
          iconName={icon}
          context="content"
        />
      </ButtonLinkComponent>
    </div>
  )
}

export default ButtonLink
