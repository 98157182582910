import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react'
import type { ResultCard as ResultCardType, SiteConfig } from '@sh24/contentful-service'
import getEmptySetter from '../utils/get-empty-setter'

export type AppContextAttributesType = SiteConfig & {
  preview: boolean,
  translations: Record<string, string>,
  resultCards: Record<string, ResultCardType>,
}

export type AppContextType = {
  appContext: AppContextAttributesType,
  setAppContext: Dispatch<SetStateAction<AppContextAttributesType>>,
}

const AppContext = createContext<AppContextType>({
  appContext: {
    featureFlags: {},
    preview: false,
    translations: {},
    resultCards: {},
  },
  setAppContext: getEmptySetter(),
})

export const useAppContext = () => useContext(AppContext)

export default AppContext
