import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { imageFactory } from '@sh24/contentful-service/factories'
import { Blockquote } from '@sh24/ui-components'
import EmbeddedImage from '../../components/EmbeddedImage/embedded-image'
import VideoEmbedModule from '../../components/Modules/VideoEmbedModule/video-embed-module'
import InlineLink from '../../components/InlineLink/inline-link'
import CalloutModule from '../../components/Modules/CalloutModule/callout-module'
import PersonSnippetModule from '../../components/Modules/PersonSnippetModule/person-snippet-module'
import TeamModule from '../../components/Modules/TeamModule/team-module'
import TypeformEmbedModule from '../../components/Modules/TypeformEmbedModule/typeform-embed-module'
import FrontformEmbedModule from '../../components/Modules/FrontformEmbedModule/frontform-embed-module'
import UL from '../../components/Modules/ContentModule/ul'
import OL from '../../components/Modules/ContentModule/ol'
import ResultsButton from '../../components/ResultsButton/results-button'
import processText from '../process-text'
import { replacementTokenInlineNodesMap, replacementTokenBlockNodesMap } from './replacement-token'
import renderNode from './render-node'
import PillModule from '../../components/Modules/PillModule/pill-module'
import ContentLinkModule from '../../components/Modules/ContentLinkModule/content-link-module'

export const standardBlockNodesMap = {
  ...replacementTokenBlockNodesMap,
  contentImage: (target) => (<EmbeddedImage {...target} />),
  contentLink: (target) => (<ContentLinkModule {...target} />),
  embed: (target) => {
    const embeds = {
      youtube: VideoEmbedModule,
      typeform: TypeformEmbedModule,
      frontform: FrontformEmbedModule,
    }
    const Component = embeds[target?.embedType]

    return Component ? <Component key={target.id} {...target} /> : null
  },
  callout: (target) => <CalloutModule {...target} embed />,
  personSnippet: (target) => (
    <div key={target.id} className="mt-md mb-md"><PersonSnippetModule {...target} /></div>
  ),
  team: (target) => <TeamModule {...target} />,
  resultsButton: (target) => (<ResultsButton {...target} />),
  pill: (target) => (<PillModule {...target} />),
}

export const standardInlineNodesMap = {
  ...replacementTokenInlineNodesMap,
  contentLink: (target) => {
    if (!target.url) return target.text

    return <InlineLink key={target.id} {...target} context="content" />
  },
}

const renderAsset = (node) => {
  const { data } = node
  const unsupported = ['application/pdf']
  const image = imageFactory(data.target)
  if (unsupported.includes(image.mimeType)) return null
  return image?.src ? <EmbeddedImage image={image} /> : null
}

const renderHr = () => (
  <hr className="mt-md mb-md" />
)

const renderUL = (node, children) => (
  <UL>
    {children}
  </UL>
)

const renderOL = (node, children) => (
  <OL>
    {children}
  </OL>
)

const renderBlockQuote = (node, children) => (
  <Blockquote>{children}</Blockquote>
)

const renderInlineNode = renderNode(standardInlineNodesMap)

const standardOptions = {
  renderNode: {
    [INLINES.ENTRY_HYPERLINK]: renderInlineNode,
    [INLINES.EMBEDDED_ENTRY]: renderInlineNode,
    [BLOCKS.EMBEDDED_ASSET]: renderAsset,
    [BLOCKS.EMBEDDED_ENTRY]: renderNode(standardBlockNodesMap),
    [BLOCKS.HR]: renderHr,
    [BLOCKS.QUOTE]: renderBlockQuote,
    [BLOCKS.UL_LIST]: renderUL,
    [BLOCKS.OL_LIST]: renderOL,
  },
  renderText: processText,
}

export default standardOptions
