import React from 'react'

const FontPreloads = ({ fonts, Head }) => {
  const fontsToPreload = fonts.filter((font) => ['400', '500'].includes(font.weight))

  const renderLinks = () => fontsToPreload.map((font) => {
    const { name, weight, formats } = font
    // only preload the most likely to be used format available
    let format
    if (formats.includes('ttf')) format = 'ttf'
    if (formats.includes('woff')) format = 'woff'
    if (formats.includes('woff2')) format = 'woff2'
    if (!format) return null

    const href = `/fonts/${name}/${weight}.${format}`
    const testid = process.env.NODE_ENV === 'test' ? `${name}-${weight}-${format}` : null

    return (
      <link
        data-testid={testid}
        key={href}
        rel="preload"
        as="font"
        href={href}
        type={`font/${format}`}
        crossOrigin="anonymous"
      />
    )
  })

  return (
    <Head>
      {renderLinks()}
    </Head>
  )
}

export default React.memo(FontPreloads)
